






import { Component, Vue } from "vue-property-decorator";
import AuthComponent from "@/components/auth/AuthComponent.vue";

@Component({
  components: {
    AuthComponent
  },
  meta: {
    // sets document title
    title: "Auth - Tarjeta Iquique",
    // optional; sets final title as "Index Page - My Website", useful for multiple level meta
    //titleTemplate: title => `${title} - My Website`,

    // meta tags
    meta: {
      description: {
        name: "description",
        content: "Auth Tarjeta Iquique."
      },
      keywords: { name: "keywords", content: "Tarjeta Iquique" },
      equiv: {
        "http-equiv": "Content-Type",
        content: "text/html; charset=UTF-8"
      }
    }
  }
})
export default class AuthView extends Vue {}
